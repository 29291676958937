import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">合同会社GASHIT SOLUTION</div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-8">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        トップページ
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        会社概要
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/products">
                        開発プラン・サービス内容
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-8">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/work">
                        開発実績
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        お問い合わせ
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/privacy">
                        プライバシーポリシー
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
